.bg-dark {
  /* background-color:#697B90!important;     */
  background-color: #510402 !important;
}
.card {
  display: flex;
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 10px;
  padding: auto;
  background-color: #fbeaea;
  position: relative;
  bottom: 10;
}
/* .bottomcard{
  
 
  margin:10px;
  flex-direction: row;

  
} */
/* .card-group{
    display: flex;
    margin: 40px;
    background-color: rgb(240, 236, 239);
    position: relative;
    margin-bottom: 20px;
} */
/* .navigation{
    margin:  0px !important;
    padding: 0px !important;
  
} */
.navbar-dark .navbar-nav .nav-link {
  color: #f8f9fa;
  font-size: 15px;
  justify-content: space-between;
  justify-self: center;
}
.navbar-nav .nav-link {
  padding-right: 1.5rem;
  padding: left 1.5em;
}
.header {
  display: flex;
  width: 100%;
  height: 150px;
  justify-content: space-between;

  background-color: #fff;
}
.logo {
  height: 120px;
  justify-content: center;
}
.price {
  margin: 20px;
  color: #000000 !important;
  font-size: 10px;
}
.btn-primary {
  color: rgb(3, 1, 1);
  background-color: #ffc107 !important;

  height: 30px;
  margin-bottom: 15px;
  font-size: 15px;
  padding: 5px;
}
.search {
  margin-top: 0px;
  font-size: 10px;
  color: #697b90 !important;
}
/* footer */
.footer {
  /* height: 80px;
   padding-bottom:80px;
   border-top: '1px solid #ddd'; */
  /* lineHeight: '60px',
backgroundColor: 'white' */
  margin-top: auto;
  position: sticky;
}
.py-2 {
  padding-bottom: 0.5rem !important;
}
.small {
  line-height: 80%;
}
/* socialmedia */
.social-container {
  display: flex;
  background: #ac8c00cc;
  justify-content: flex-end;
  padding: 5px;
  margin-top: 10px;
  margin-right: 0px;
}
a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}
a.social:hover {
  transform: translateY(-2px);
}
a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: rgb(243, 72, 72);
}
/* searchbar */
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
html,
body,
#root {
  height: 100%;
  background-color: #fff;
}
#root {
  display: flex;
  flex-direction: column;
}
.small {
  font-size: 10px;
}
.text {
  display: flex;
  color: #697b90 !important;
  justify-content: flex-start;
  align-items: center;
}
.show > .dropdown-menu {
  display: block;
  position: absolute;
  margin: 10px;
}
.loginfield {
  display: flex;
  /* margin: 60px; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #7a0401 !important;
  color: #e6c847;
}
.mb-3,
.my-3 {
  display: flex;
  margin-bottom: 1rem !important;

  /* margin: 50px; */
  align-items: center;
}
.marq {
  background-color: #f5f2e4cc;
  color: rgb(184, 24, 24);
}
.border {
  border: 8px solid #dee2e6 !important;
}

.bg-light {
  background-color: #cd2635 !important;
  color: #000000;
}
/* .loginfield{
  background-image: url("https://d32t9jl4s128ar.cloudfront.net/assets/banner.png");
        height: 50vh;
} */
/* @media (min-width: 992px) {
  .loginfield {
    margin-top: -58.59px;
  }
} */
.btn-outline-secondary {
  background-color: #ffc107 !important;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 260px;
}
.btn-warning {
  color: #212529;

  border-color: #ffc107;
  width: 100px;
  padding: 5px;
  color: #000000;

  margin-top: 20px;
  margin-bottom: 10px;
}

.PhoneInput {
  background-color: #fff;
  padding: 2px 2px 2px 6px;
  width: 260px;
}
.PhoneInputInput {
  border: 0;
}
.Account{
  display: flex;
  flex-direction: row;
}
input{
  background-color: #fff;
  padding: 2px 2px 2px 6px;
  width: 260px;
}
.logout{
  width: 50px;
  height: 30px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

    #userlogin {
      
        margin-top: 50px;
    }
  }
.rating{
  margin: 4px;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
}