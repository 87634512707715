/* 
*****************************************
*                                       *
*                                       *
*       Siva Sai Royal                    * 
*       Full stack Developer    *
*       DHRUTHZUCI TECHNOLOGY           *
*                    *
*                *
*                                       *
*                                       *
*****************************************
*/

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "PT sans", "Roboto", "BankGothic", "Arial", sans-serif;
}

@import url(//db.onlinewebfonts.com/c/cd0161509171ec0b9558c548d8e9c26d?family=Bank+Gothic);

body {
    font-family: "PT sans", "Roboto", "Arial",  "BankGothic", Helvetica, sans-serif !important;
    font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
    color: #707070;
}


:root {
    --theme-darck-Red: #510402;
    --theme-red: #7A0401;
    --theme-orange: #F7A51B;
    --theme-white: #ffffff;


}

@media (min-width: 768px) {
    :root {
      font-size: calc(1rem + ((1vw - 7.68px) * 0.6944));
      min-height: 0vw;
    }
  }
  @media (min-width: 1920px) {
    :root {
      font-size: 24px;
    }
  }
  
/***********  Hearder     ************/

header {
    background-color: #510402;
    width: 100%;
    height: 100px;
    color: #F7A51B;
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
}

#header-left {
   width: 45%;
   color: #F7A51B;
   font-size: 14px;
   margin-top: 10px;
}
#time-date {
    position: absolute;
    top: 8px;
}
 .time-icon {
    fill: var(--theme-orange);
    padding-right: 4px;
}
.gold-rate-box {
    background: #980405;
    color: #fff;
    padding: 0px 10px;
    position: absolute;
    overflow: auto;
    height: 30px;
    -ms-overflow-style: none;
    top: 36px;
}
.gold-rate-box::-webkit-scrollbar {
    display: none;
  }
/* increase duration to speed up scroll */
.marquee {
    animation:scrollUp 6s linear 1s infinite;
}
.marquee p {
    font-family: "BankGothic", "PT sans", "Roboto", "Arial", sans-serif;
    font-size: 16px;
    line-height: 1.2;
}
.marquee p i {
    font-size: 14px;
    font-weight: 700;
  }
@supports (transform:translate3d(0px,0px,0px)) {
	.marquee-wrap {overflow:hidden; }
	.marquee{padding-top:4px;}
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.gold-rate-box {
        overflow:hidden; 
    }/* ie11 hack */
	.marquee { 
        padding-top:4px;
    }
}
@keyframes scrollUp {
	from {transform:translateY(0%);}
	to {transform:translateY(-100%);}
}
.marquee:hover{
    animation-play-state:paused;
}
#contact {
    position: absolute;
    top: 70px;
}
.mobile-no-icon {
    fill: var(--theme-orange);
    padding-right: 4px;
}
.location-icon {
    fill: var(--theme-orange);
    padding-right: 4px;
    padding-left: 4px;
}
#svj-logo {
    margin: 5px auto;
}
#svj-logo img {
    width: 108px;
    height: auto;

}

#header-right {
    width: 45%;
    color: #F7A51B;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}
.cart-login {
    display: flex;
    justify-content: end;
    height: 30px;
}
.cart-icon {
    fill: var(--theme-orange);
    padding: 0px 0px 4px 0px;
    margin-top: 5px;
}
#user-login {
    text-align: left;
    padding: 8px 4px 4px 4px;
    line-height: 0;
   margin-top: 5px;
    }
#user-login span {
        color: #FFF;
        font-size: 16px;
        line-height: 0;
    }
#user-login span small {
    color: #F7A51B;
    font-size: 10px;
    position: relative;
    top: -2px;
    }


.logout-icon {
    fill: var(--theme-orange);
    padding-left: 4px;
    position: relative;
    top: -6px;
}

.logout-icon:hover {
    fill: var(--theme-white);
    cursor: pointer;
 
}
#user-login img {
    width: 30px;
    height: 30px;
}

#search-box {
    width: 300px;
    height: 34px;
    position: absolute;
    top: 56px;
}
#search-box .form-control {
    height: 34px !important;
    border-radius: 0px !important;
    border-style: solid !important;
    border-width: 1px 0px 1px 1px!important;
    border-color: #F7A51B !important;
    font-size: 12px;
    color: #707070;
}

#search-box .input-group-text {
    border-radius: 0px !important;
    padding: 0px 10px !important;
    border-style: solid !important;
    border-width: 1px 1px 1px 0px!important;
    border-color: #F7A51B !important;
    background-color: #980405;
    font-size: 18px;
    color: #F7A51B;

}

/***********  nav     ************/
nav {
    width: 100%;
    height: 40px !important;
    padding: 0px !important;
    margin: 0px;
    border-top:1px solid #F7A51B;
    border-bottom:1px solid #F7A51B;
}
.navbar {
    margin: 0px !important;
    background: #980405;
    color: #fff;
    border-radius: 0;
}

.navbar-dark .navbar-nav .nav-link {
    color: #ffffff !important;
    margin: 0px !important;
}
.navbar .navbar-nav > a {
    padding: 4px 20px 4px 20px !important;
    font-size: 18px;
    font-family: "BankGothic", "PT sans", "Roboto", "Arial", sans-serif;
    border-radius: 30px;
}

.navbar .navbar-nav > a:hover,
.navbar .navbar-nav > a:focus {
    color: #510402 !important;
    background: #F7A51B !important;
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
    color: #510402 !important;
    background: #F7A51B !important;

}

.navbar-dark .navbar-toggler-icon {
    position: absolute;
    right: 1.3rem;
    top: .4rem;

}


/*********** header nav  @media  ************/

@media (min-width: 992px) {
    .navbar-dark .navbar-nav .nav-link {
        padding: 4px 20px 4px 20px !important;
    }

}

@media (max-width: 991px) {
header {
    background-color: #510402;
    width: 100%;
    height: 140px;
    color: #F7A51B;
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
}
#svj-logo img {
    width: auto;
    height:90px ;
    margin-top: 17px;
  }
#time-date {
   display: none;
}
.gold-rate-box {
    width: 100%;
    background: #980405;
    color: #fff;
    position: absolute;
    top:0px;
    left:0px;
    overflow: auto;
    height: 24px;
    -ms-overflow-style: none;
}
.marquee p {
    font-family: "BankGothic", "PT sans", "Roboto", "Arial", sans-serif;
    font-size: 14px;
    line-height: 1.2;
}
.marquee p i {
    font-size: 18px;
    font-weight: 700;
  }
#contact {
    display: none;
}
.cart-icon {
    display: none;
}
#user-login span {
    display: none;
}
.logout-icon {
    position: relative;
    top: 92px;
    right: 10px;
}
#search-box {
    width: 80%;
    position: absolute;
    top: 112px;
    left: 20px;
}
#search-box .form-control {
    height: 26px !important;
    font-size: 10px;
}
#search-box .input-group-text {
    padding: 0px 8px !important;
    font-size: 16px;
}
.navbar-dark .navbar-nav .nav-link {
    padding: 10px 24px;
}
.navbar-collapse {
    background: #980405;
    color: #fff;
    margin-top: 30px;
    padding: 0px !important;
    width: 100%;
}
.navbar-dark .navbar-toggler {
    color: #F7A51B !important;

}


}
/*********** header nav  @media  ************/